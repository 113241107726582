import React, { useState, useRef, useEffect } from "react";
import styles from "./Dropdown.module.css";

/**
 * @type {React.FC<{className: string; question: any; content: any}>}
 */
function Dropdown({ className, question, content }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const answerRef = useRef(null);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (isExpanded && answerRef.current) {
      answerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isExpanded]);

  return (
    <div className={`${styles.columns} ${className}`}>
      <button
        className={styles.faqquestion}
        aria-expanded={isExpanded}
        onClick={handleToggle}
      >
        <p>
          <span className={styles.faqtext}>{question} ▼</span>
        </p>
      </button>
      <div ref={answerRef} className={styles.faqanswer} hidden={!isExpanded}>
        <p>{content}</p>
      </div>
    </div>
  );
}

export default Dropdown;
