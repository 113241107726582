import React from "react";
import { Link } from "../";
import { Button } from "../";
import styles from "./LinkButton.module.css";

const LinkButton = ({ to, label }) => {
  return (
    <Link to={to}>
      <Button className={styles.button} label={label}></Button>
    </Link>
  );
};

export default LinkButton;
