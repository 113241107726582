import React from "react";
import styles from "./HomePage.module.css";
import { useTranslation } from "react-i18next";
import { SectionHomepageIntro } from "../../templates";
import { SectionTenantHeimhubProcess } from "../../templates";
import { SectionHomepageContact } from "../../templates";
import { SectionLandingSlider } from "../../templates";
import { LinkButton } from "../../atoms";
import { Section } from "../../molecules";

const HomePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <SectionHomepageIntro />
      <SectionLandingSlider />
      <SectionTenantHeimhubProcess />
      <Section className={styles.learnmore}>
        <p>{t("want_to_learn_more")}</p>
        <LinkButton to={"tenant"} label={t("read_more")}></LinkButton>
      </Section>
      <SectionHomepageContact />
    </div>
  );
};

export default HomePage;
