import styles from "./HomepageIntro.module.css";
import { useTranslation } from "react-i18next";
import Section from "../../molecules/Section";
import { Image } from "../../atoms";
import React from "react";
import { LinkButton } from "../../atoms";

const HomepageIntro = () => {
  const { t } = useTranslation();

  return (
    <Section className={styles.section}>
      <div className={styles.overlaywrapper}>
        {/* Background Image */}
        <Image
          src="Images/dreamstime_l_325052066.jpg"
          alt="Background"
          className={styles.backgroundimage}
        />

        {/* Overlay Content */}
        <div className={styles.overlaycontent}>
          {/* Left Side: Landlord */}
          <div className={`${styles.card} ${styles.landlordcard}`}>
            <p>{t("home_page_intro.home_page_intro_landlord")}</p>
            <h2>{t("home_page_intro.home_page_intro_landlord_description")}</h2>
            <LinkButton to={"landlord"} label={t("read_more")}></LinkButton>
          </div>

          {/* Right Side: Tenant */}
          <div className={`${styles.card} ${styles.tenantcard}`}>
            <p>{t("home_page_intro.home_page_intro_tenant")}</p>
            <h2>{t("home_page_intro.home_page_intro_tenant_description")}</h2>
            <LinkButton to={"tenant"} label={t("read_more")}></LinkButton>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HomepageIntro;
